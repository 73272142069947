import { useMemo } from "react";
import countriesSubset from "@/data/countries-subset.json";

interface Country {
  value: string;
  label: string;
  latlng: number[];
  region: string;
}

export const useCountries = () => {
  const countries: Country[] = useMemo(
    () =>
      countriesSubset.map((country) => ({
        value: country.cca2,
        label: country.name,
        latlng: country.latlng,
        region: country.region,
      })),
    [],
  );

  const getAll = () => countries;

  const getByValue = (value: string) => {
    return countries.find((item) => item.value === value);
  };

  const getByName = (label: string) => {
    return countries.find((item) => item.label === label);
  };

  return {
    getAll,
    getByValue,
    getByName,
  };
};

export default useCountries;

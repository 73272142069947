import React, { useState } from "react";
import Modal from "./Modal";
import useShareModal from "@/app/hooks/useShareModal";
import { toast } from "react-hot-toast";
import {
  FaCopy,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";

interface ShareModalProps {
  url: string;
  title: string;
}

const ShareModal: React.FC<ShareModalProps> = ({ url, title }) => {
  const shareModal = useShareModal();
  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url,
    )}`;
    window.open(facebookUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url,
    )}&text=${encodeURIComponent(title)}`;
    window.open(twitterUrl, "_blank");
  };

  const shareOnInstagram = () => {
    const instagramUrl = `https://www.instagram.com/?url=${encodeURIComponent(
      url,
    )}`;
    window.open(instagramUrl, "_blank");
  };

  const shareOnWhatsApp = () => {
    const whatsAppUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      title,
    )}%20${encodeURIComponent(url)}`;
    window.open(whatsAppUrl, "_blank");
  };

  const copyLink = () => {
    navigator.clipboard.writeText(url);
    toast.success("Link copied!");
  };

  let bodyContent = (
    <div className="grid md:grid-cols-2 gap-4">
      <button
        onClick={copyLink}
        className="mx-3 my-1 px-3 py-2 rounded-lg border border-gray-300"
      >
        <FaCopy
          size={24}
          className="
            text-neutral-700
            absolute
          "
        />
        <span className="ml-8">Copy link</span>
      </button>
      <button
        onClick={shareOnWhatsApp}
        className="mx-3 my-1 px-3 py-2 rounded-lg border border-gray-300"
      >
        <FaWhatsapp
          size={24}
          className="
            text-neutral-700
            absolute
          "
        />
        <span className="ml-8">WhatsApp</span>
      </button>
      <button
        onClick={shareOnInstagram}
        className="mx-3 my-1 px-3 py-2 rounded-lg border border-gray-300"
      >
        <FaInstagram
          size={24}
          className="
            text-neutral-700
            absolute
          "
        />
        <span className="ml-8">Instagram</span>
      </button>
      <button
        onClick={shareOnFacebook}
        className="mx-3 my-1 px-3 py-2 rounded-lg border border-gray-300"
      >
        <FaFacebook
          size={24}
          className="
            text-neutral-700
            absolute
          "
        />
        <span className="ml-8">Facebook</span>
      </button>
      <button
        onClick={shareOnTwitter}
        className="mx-3 my-1 px-3 py-2 rounded-lg border border-gray-300"
      >
        <FaTwitter
          size={24}
          className="
            text-neutral-700
            absolute
          "
        />
        <span className="ml-8">Twitter</span>
      </button>
    </div>
  );

  return (
    <Modal
      isOpen={shareModal.isOpen}
      title="Share this listing"
      onClose={shareModal.onClose}
      body={bodyContent}
    />
  );
};

export default ShareModal;
